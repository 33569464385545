<template>
  <v-container>
    <v-btn
      v-if="mobile"
      color="rgb(181,147,223)"
      prepend-icon="mdi-menu"
      class="text-none mb-2 ml-2"

      variant="flat"
      @click.stop="drawer = !drawer"
      flat
    >Menu
    </v-btn>
    <v-card v-if="mobile">
      <v-layout>
        <v-navigation-drawer
          v-model="drawer"
          temporary
          :style="{'transition-duration': '0.2s'}"
        >
          <v-list-item>
            <v-list-item-title justify="center" class="text-h6 text-center">Nick & Hannah</v-list-item-title>
          </v-list-item>

          <v-divider></v-divider>
          <v-list density="compact" nav>
            <v-list-item
              v-for="item in getItems()"
              :key="item"
              :title="item"
              :active="item === selectedMobile"
              @click="selectedNewMobile(item)"
            ></v-list-item>
          </v-list>
        </v-navigation-drawer>

        <v-main>
          <v-container fill-height fluid>
            <v-row align="center" justify="center">
              <v-col cols="12">
                <InviteTab v-if="selectedMobile === 'Your Invite'" />
                <RSVPTab v-if="selectedMobile === 'RSVP'" />
                <AccomodationTab v-if="selectedMobile === 'Accomodation'" />
                <RegistryListTab v-if="selectedMobile === 'Registry List'" />
                <FAQTab v-if="selectedMobile === 'FAQ'" />
              </v-col>
            </v-row>
          </v-container>
        </v-main>
      </v-layout>
    </v-card>
    <v-card v-if="!mobile">
      <v-tabs
        v-model="tab"
        fixed-tabs
        color="rgb(181,147,223)"
      >
        <v-tab
          v-for="item in getItems()"
          :key="item"
          :text="item"
          :value="item"
        ></v-tab>
      </v-tabs>
      <v-tabs-window v-model="tab">
        <v-tabs-window-item
          v-for="item in getItems()"
          :key="item"
          :value="item"
        >
          <v-container fill-height fluid>
            <v-row align="center" justify="center">
              <v-col cols="12">
                <InviteTab v-if="item === 'Your Invite'" />
                <RSVPTab v-if="item === 'RSVP'" />
                <AccomodationTab v-if="item === 'Accomodation'" />
                <RegistryListTab v-if="item === 'Registry List'" />
                <FAQTab v-if="item === 'FAQ'" />
              </v-col>
            </v-row>
          </v-container>
        </v-tabs-window-item>
      </v-tabs-window>
    </v-card>
  </v-container>
</template>

<script>
import InviteTab from '@/components/InviteTab.vue'
import RSVPTab from '@/components/RSVPTab.vue'
import AccomodationTab from '@/components/AccomodationTab.vue'
import RegistryListTab from '@/components/RegistryListTab.vue'
import FAQTab from '@/components/FAQTab.vue'
import { mapGetters } from 'vuex'

export default {
  name: "HomeView",
  computed: {
    ...mapGetters('auth', {
      attendingEvening: 'attendingEvening',
      attendingBreakfast: 'attendingBreakfast'
    }),
    mobile() {
      return this.$vuetify.display.mobile
    },
  },
  data() {
    return {
      tab: null,
      drawer: false,
      selectedMobile: 'Your Invite',
    }
  },
  methods: {
    getItems() {
      return this.attendingEvening || this.attendingBreakfast
        ? [ 'Your Invite', 'RSVP', 'Accomodation', 'Registry List', 'FAQ' ]
        : [ 'Your Invite', 'Registry List', 'FAQ' ]
    },
    selectedNewMobile(item) {
      this.selectedMobile = item;
      this.drawer = false;
    }
  },
  components: {
    InviteTab,
    RSVPTab,
    AccomodationTab,
    RegistryListTab,
    FAQTab,
  },
}
</script>

<style>
.sticky {
  position: sticky;
  top: 16px;
  z-index: 1;
}

</style>
