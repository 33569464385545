<template>
  <v-container fluid fill-height flex>
    <v-col fill-height cols="12" align="center" justify="center" align-self="center">
      <v-row justify="center" align="center">
        <v-card class="mx-auto" width="400">
          <template v-slot:title>
            <span class="font-weight-blue">Wedding of Nick & Hannah!</span>
          </template>

          <v-text-field
            v-model="passcode"
            :error-messages="errorMessage"
            label="Enter Passcode"
            class="mx-8"
            :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
            :type="visible ? 'text' : 'password'"
            hint="You can find the passcode on your invite"
            persistent-hint
            @click:append-inner="visible = !visible"
            prepend-inner-icon="mdi-lock-outline"
            @keyup.enter="submit"
            clearable
          ></v-text-field>

          <v-row class="ml-8 mb-4 mt-4">
            <div class="text-caption text-decoration-none text-blue forgotPassword" @click="openContactUsDialog">
              <p>Forgot login password?</p>
            </div>
          </v-row>
          <template v-slot:actions>
            <v-btn
              id="login-button"
              :disabled="passcode === null || passcode === ''"
              append-icon="mdi-chevron-right"
              color="rgb(181,147,223)"
              text="Submit"
              variant="elevated"
              @click="submit"
              block
              filled
            ></v-btn>
          </template>
        </v-card>
        <v-dialog
          v-model="contactUsDialog"
          max-width="500px"
        >
          <v-card>
            <v-card-title class="text-wrap">
              <span class="text-h5">Contact Us</span>
            </v-card-title>
            <v-card-text v-if="contactUsSubmitted">
              <div class="text-center text-subtitle-2">Thank you for contacting us, we'll get back to you as soon as we can!</div>
            </v-card-text>
            <v-card-text v-if="!contactUsSubmitted">
              <v-text-field
                v-model="contactUsName"
                :rules="[rules.required]"
                label="Name"
              ></v-text-field>
              <v-text-field
                v-model="contactUsEmail"
                :rules="[rules.requiredEmail]"
                label="Your E-mail"
              ></v-text-field>
              <v-textarea
                v-model="contactUsBody"
                label="Additional Info"
                maxlength="200"
                counter
                single-line
              ></v-textarea>
            </v-card-text>
            <v-card-actions v-if="!contactUsSubmitted">
              <v-btn
                color="rgb(181,147,223)"
                variant="text"
                @click="contactUsCancel"
              >
                Cancel
              </v-btn>
              <v-btn
                color="rgb(181,147,223)"
                variant="elevated"
                :disabled="!contactUsName || !contactUsEmail || !this.validateEmail(contactUsEmail)"
                @click="contactUsSubmit"
              >
                Confirm
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-col>
  </v-container>
</template>

<script>
import {
  ADMIN,
  ADMIN_PASSWORD,
  ALL_DAY,
  ALL_DAY_PASSWORD,
  CHURCH_AND_EVENING,
  CHURCH_AND_EVENING_PASSWORD,
  CHURCH_ONLY,
  CHURCH_ONLY_PASSWORD,

  EMAIL_JS_PUBLIC_KEY,
  EMAIL_JS_SERVICE_ID,
  EMAIL_JS_FORGOTTEN_PASSCODE_TEMPLATE_ID,
} from '@/config';
import { mapGetters } from 'vuex'
import emailjs from '@emailjs/browser'

export default {
  name: "LoginView",
  computed: {
    ...mapGetters('auth', {
      authenticated: 'isAuthenticated',
      accountType: 'getAccountType'
    })
  },
  data() {
    return {
      passcode: null,
      errorMessage: null,
      visible: false,

      // Contact Us
      contactUsDialog: false,
      contactUsName: null,
      contactUsEmail: null,
      contactUsBody: null,
      contactUsSubmitted: false,

      rules: {
        required: value => !!value || 'Field is required',
        requiredEmail: value => (!!value && this.validateEmail(value)) || 'Please enter a valid e-mail',
      },
    }
  },
  methods: {
    submit() {
      const inputtedHash = this.convertHash(this.passcode);
      if (inputtedHash === ADMIN_PASSWORD) {
        this.$store.dispatch('auth/setAccountType', ADMIN)
      }

      if (inputtedHash === ALL_DAY_PASSWORD) {
        this.$store.dispatch('auth/setAccountType', ALL_DAY)
      }

      if (inputtedHash === CHURCH_AND_EVENING_PASSWORD) {
        this.$store.dispatch('auth/setAccountType', CHURCH_AND_EVENING)
      }

      if (inputtedHash === CHURCH_ONLY_PASSWORD) {
        this.$store.dispatch('auth/setAccountType', CHURCH_ONLY)
      }

      if (!this.authenticated) {
        this.errorMessage = 'Incorrect passcode, please try again'
      } else {
        this.errorMessage = null
      }
    },

    convertHash(input) {
      var hash = 0, i, chr;
      if (input.length === 0) return hash;
      for (i = 0; i < input.length; i++) {
          chr   = input.charCodeAt(i);
          hash  = ((hash << 5) - hash) + chr;
          hash |= 0;
      }
      return hash;
    },

    validateEmail(email) {
      return Boolean(email
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ))
    },

    openContactUsDialog() {
      this.contactUsDialog = true
    },

    contactUsSubmit() {
      const submissionParams = {
        from_name: this.contactUsName,
        from_email: this.contactUsEmail,
        message: this.contactUsBody,
      }

      emailjs
        .send(
          EMAIL_JS_SERVICE_ID,
          EMAIL_JS_FORGOTTEN_PASSCODE_TEMPLATE_ID,
          submissionParams,
          {
            publicKey: EMAIL_JS_PUBLIC_KEY,
          }
        )
        .then(
          () => {
            console.log('SUCCESS!');
          },
          (error) => {
            console.log('FAILED...', error);
          },
        )

      this.contactUsSubmitted = true;
    },

    contactUsCancel() {
      this.contactUsDialog = false
    }
  },
};
</script>

<style>
#login-button {
  margin-right: 5px;
  margin-left: 5px;
  display: flex;
}

.forgotPassword {
  cursor: pointer;
  padding:0;
  margin:0;
}
</style>
