import {
  ADMIN,
  ALL_DAY,
  CHURCH_AND_EVENING,
} from '@/config';

const state = {
  accountType: null
}

const getters = {
  isAuthenticated: (state) => !!state.accountType,
  getAccountType: (state) => state.accountType,
  attendingBreakfast: (state) => state.accountType === ADMIN || state.accountType === ALL_DAY,
  attendingEvening: (state) => state.accountType === ADMIN || state.accountType === ALL_DAY || state.accountType === CHURCH_AND_EVENING,
}

const mutations = {
  setAccountType(state, accountType) {
    state.accountType = accountType;
  }
};

const actions = {
  setAccountType({commit}, accountType) {
    commit('setAccountType', accountType);
  },

  unSetAccountType({commit}) {
    commit('setAccountType', null);
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
