<template>
  <v-container class="pa-0 ma-0">
    <v-img src="~@/assets/rsvp.jpg" class="mb-2" height="50vh" cover></v-img>
    <a target="_blank" class="text-center" style="font-size:8pt;width:70px;display:inline-block;" href="https://www.emilyscotneyphotography.com/">
      Emily Scotney Photography
    </a>
    <v-icon size="xx-small" icon="mdi-open-in-new"></v-icon>
    <div class="text-h4 mb-4 text-center">RSVP</div>
    <v-row v-if="submitted">
      <v-col cols="12">
        <div class="text-h6 text-center">
          Thank you for your response!
        </div>
      </v-col>
    </v-row>
    <v-row v-if="!submitted" justify="center" class="mt-6">
      <div class="text-body-1 text-center mb-4">
        <b>Please RSVP by the 31st January 2025!</b>
      </div>
      <div class="text-body-1 text-center mb-4">
        Add each guest listed on your invite by clicking 'Add Guest' and filling out the form.<br/>
        Please make sure you include each guest on the envelope of your invite, including children, babies and those who cannot attend.
      </div>
      <div class="text-body-1 text-center">
        Once all guests on your invite have been added, click "Submit".
      </div>
    </v-row>
    <template v-if="!submitted">
      <v-row v-for="(person, index) in people" :key="index" justify="center" class="mt-6">
        <v-card
          :color="index % 2 == 0 ? 'rgb(181,147,223)' : 'rgb(100, 149, 237)'"
          variant="outlined"
          width="400px"
        >
          <template v-slot:title>
            <span>{{ person.name }}</span>
          </template>
          <v-card-text>
            <v-row class="mt-2">
              <v-col class="offset-1 col-5 text-start pa-1">
                <div class="font-weight-bold">Attending:</div>
              </v-col>
              <v-col class="col-6 text-start pa-1">
                <div class="font-weight-regular">{{ person.attending }}</div>
              </v-col>
            </v-row>
            <v-row v-if="attendingBreakfast && person.attending === 'Yes'">
              <v-col class="offset-1 col-5 text-start pa-1">
                <div class="font-weight-bold">Type:</div>
              </v-col>
              <v-col class="col-6 text-start pa-1">
                <div class="font-weight-regular">{{ person.type }}</div>
              </v-col>
            </v-row>
            <v-row v-if="attendingBreakfast && person.attending === 'Yes' && person.food">
              <v-col class="offset-1 col-5 text-start pa-1">
                <div class="font-weight-bold">Food choice:</div>
              </v-col>
              <v-col class="col-6 text-start pa-1">
                <div class="font-weight-regular">{{ person.food }}</div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :color="index % 2 == 0 ? 'rgb(181,147,223)' : 'rgb(100, 149, 237)'"
              variant="elevated"
              @click="deleteItem(person)"
            >
              Remove
            </v-btn>
            <v-btn
              :color="index % 2 == 0 ? 'rgb(181,147,223)' : 'rgb(100, 149, 237)'"
              variant="elevated"
              @click="editItem(person)"
            >
              Edit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-row>
    </template>

    <v-row v-if="!submitted">
      <v-dialog
        v-model="addPersonDialog"
        max-width="500px"
      >
        <template v-slot:activator="{ props }">
          <v-container>
            <v-row justify="center">
              <v-btn
                class="mb-8 mt-8"
                color="rgb(181,147,223)"
                variant="elevated"
                v-bind="props"
              >
                Add Guest
              </v-btn>
            </v-row>
          </v-container>
        </template>
        <v-card>
          <v-card-title class="text-wrap">
            <span class="text-h5">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="editedItem.name"
                    :rules="[rules.required]"
                    hint="Please insert your full name"
                    label="Full Name"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-select
                    v-model="editedItem.attending"
                    :rules="[rules.required]"
                    :items="attendingItems"
                    @update:model-value="userSelectedNotAttending"
                    label="Attending"
                  ></v-select>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-select
                    v-model="editedItem.type"
                    v-if="attendingBreakfast"
                    :disabled="editedItem.attending === 'No'"
                    :rules="[rules.required]"
                    :items="typeItems"
                    hint="Please select 'Adult' for anyone over 12 years old"
                    label="Guest Type"
                    persistent-hint
                  ></v-select>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-select
                    v-model="editedItem.food"
                    v-if="attendingBreakfast"
                    :disabled="editedItem.attending === 'No' || editedItem.type === 'Under 2'"
                    :rules="[rules.required]"
                    :items="foodItems"
                    persistent-hint
                    hint="All meals served with Rosemary & Garlic Roast Potatoes, Yorkshire Pudding, Seasonal Vegatables and Gravy"
                    label="Food Choice"
                    class="mb-1"
                  >
                  </v-select>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-textarea
                    v-model="editedItem.additionalInfo"
                    label="Additional Info"
                    hint="Dietary Restictions, Accessibility considerations etc"
                    maxlength="200"
                    persistent-hint
                    counter
                    single-line
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="rgb(181,147,223)"
              variant="text"
              @click="close"
            >
              Cancel
            </v-btn>
            <v-btn
              color="rgb(181,147,223)"
              variant="elevated"
              @click="save"
              :disabled="!saveEditEnabled()"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="deletePersonDialog" max-width="500px">
        <v-card>
          <v-card-title class="text-wrap">
            <span class="text-h5">Delete guest: {{ editedItem.name }}?</span>
          </v-card-title>
          <v-spacer></v-spacer>
          <v-card-actions>
            <v-btn
              color="rgb(181,147,223)"
              variant="text"
              @click="closeDelete"
            >
              Cancel
            </v-btn>
            <v-btn
              color="rgb(181,147,223)"
              variant="elevated"
              @click="deleteItemConfirm"
            >
              Confirm
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row v-if="!submitted">
      <v-dialog v-model="confirmDialog" max-width="500px">
        <v-card>
          <v-card-title class="text-h5 text-wrap">Please confirm:</v-card-title>
          <v-card-text>
            <v-list>
              <v-list-item
                v-for="person in people"
                :key="person.name"
              >
                <v-list-item-title class="text-wrap">{{ person.name }}</v-list-item-title>
                <v-list-item-subtitle class="wrap-text-custom">{{ generateConfirmationText(person) }}</v-list-item-subtitle>
              </v-list-item>
            </v-list>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="rgb(181,147,223)"
              variant="text"
              @click="closeConfirm"
            >
              Cancel
            </v-btn>
            <v-btn
              color="rgb(181,147,223)"
              variant="elevated"
              @click="submit"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-container>
        <v-row justify="center">
          <v-spacer></v-spacer>
          <v-btn
            color="rgb(181,147,223)"
            variant="elevated"
            :disabled="people.length === 0"
            @click="submitConfirm"
            >
              Submit
          </v-btn>
        </v-row>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import emailjs from '@emailjs/browser'
import {
  EMAIL_JS_PUBLIC_KEY,
  EMAIL_JS_SERVICE_ID,
  EMAIL_JS_RSVP_TEMPLATE_ID,
} from '@/config';

export default {
  name: "RSVPTab",
  computed: {
    ...mapGetters('auth', {
      attendingEvening: 'attendingEvening',
      attendingBreakfast: 'attendingBreakfast'
    }),
    formTitle() {
      return this.editedIndex === -1 ? 'New Person' : 'Edit ' + this.editedItem.name
    },
    mobile() {
      return this.$vuetify.display.mobile
    },
  },
  data() {
    return {
      addPersonDialog: false,
      deletePersonDialog: false,
      confirmDialog: false,
      submitted: false,

      attendingItems: ['Yes', 'No'],
      typeItems: ['Adult', 'Under 12', 'Under 5', 'Under 2'],
      foodItems: ['Roast Chicken (GF)', 'Sweet Potato, Spinach and Goats Cheese Pie (V)', 'Mushroom and Leek Pie (Ve)(GF)'],

      rules: {
        required: value => !!value || 'Field is required',
      },
      people: [],
      editedIndex: -1,
      editedItem: {
        name: null,
        attending: null,
        type: null,
        food: null,
        additionalInfo: null,
      },
      defaultItem: {
        name: null,
        attending: null,
        type: null,
        food: null,
        additionalInfo: null,
      },
    }
  },
  watch: {
    addPersonDialog (val) {
      val || this.close()
    },
    deletePersonDialog (val) {
      val || this.closeDelete()
    },
  },
  methods: {
    editItem (item) {
      this.editedIndex = this.people.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.addPersonDialog = true
    },

    deleteItem (item) {
      this.editedIndex = this.people.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.deletePersonDialog = true
    },

    deleteItemConfirm () {
      this.people.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close () {
      this.addPersonDialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.deletePersonDialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    saveEditEnabled() {
      if (this.attendingBreakfast) {
        if (this.editedItem.attending === 'No') {
          return this.editedItem.name
            && this.editedItem.attending
        }

        if (this.editedItem.type === 'Under 2') {
          return this.editedItem.name
            && this.editedItem.attending
            && this.editedItem.type
        }

        return this.editedItem.name
          && this.editedItem.attending
          && this.editedItem.type
          && this.editedItem.food
      }

      return this.editedItem.name
          && this.editedItem.attending
    },

    save () {
      if (this.editedIndex > -1) {
        Object.assign(this.people[this.editedIndex], this.editedItem)
      } else {
        this.people.push(this.editedItem)
      }
      this.close()
    },

    submitConfirm() {
      this.confirmDialog = true
    },

    closeConfirm() {
      this.confirmDialog = false
    },

    generateConfirmationText(person) {
      if (this.attendingBreakfast) {
        if (person.attending === 'No') {
          return person.name + ' is not attending' + '.' + (person.additionalInfo ? ' Additional Info: "' + person.additionalInfo + '"' : '')
        }

        if (!person.food) {
          return person.name + ' is attending as an ' + person.type + '.' + (person.additionalInfo ? ' Additional Info: "' + person.additionalInfo + '"' : '')
        }

        return person.name + ' is attending as an ' + person.type + ' and would like the ' + person.food + '.' + (person.additionalInfo ? ' Additional Info: "' + person.additionalInfo + '"' : '')
      }

      return person.name + ' is ' + (person.attending === 'Yes' ? 'attending' : 'not attending') + '.' + (person.additionalInfo ? ' Additional Info: "' + person.additionalInfo + '"' : '')
    },

    submit() {
      const submissionParams = {
        from_name: this.people[0].name,
        message: this.people.map(item => this.generateConfirmationText(item)).join("\n\n")
      }

      emailjs
        .send(
          EMAIL_JS_SERVICE_ID,
          EMAIL_JS_RSVP_TEMPLATE_ID,
          submissionParams,
          {
            publicKey: EMAIL_JS_PUBLIC_KEY,
          }
        )
        .then(
          () => {
            console.log('SUCCESS!');
          },
          (error) => {
            console.log('FAILED...', error);
          },
        )

      this.submitted = true
    },

    userSelectedNotAttending() {
      if (this.editedItem.attending === 'No') {
        this.editedItem.food = null
        this.editedItem.type = null
      }
    },
  }
}
</script>

<style>

.v-input__details {
  padding-bottom: 6px !important;
}

.v-list-item-title {
  white-space: normal;
  word-break: break-word;
}

.wrap-text-custom {
  -webkit-line-clamp: unset !important;
  line-clamp: unset !important;
}

</style>
