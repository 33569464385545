<template>
  <v-container>
    <v-img src="~@/assets/registry-list.jpg" class="mb-2" height="50vh" cover></v-img>
    <div class="text-h4 mb-4 text-center">Registry List</div>
    <v-row justify="center">
      <div class="text-body-1 text-center mb-4">
        Having you attend our wedding is the greatest gift of all, however, if you're looking for wedding gift ideas, we've created a registry list of items that would be useful for us as we begin our new lives together.
      </div>
    </v-row>
    <v-row justify="center">
      <div class="text-body-1 text-center mb-4">
        In lieu of a wedding gift, we ask that you consider making a donation to one of our chosen charities.<br/><br/>
        <a target="_blank" href="https://grassroots.org.uk/product/give-now/">Grassroots</a>
        <v-icon size="x-small" icon="mdi-open-in-new"></v-icon>
        is a charity that Hannah has supported for many years, they aim to support people in need across many different countries through working
        with local churches.<br/><br/>
        <a target="_blank" href="https://www.britishlegion.org.uk/get-involved/ways-to-give/donate?_gl=1*pba864*_up*MQ..&gclid=Cj0KCQjw3vO3BhCqARIsAEWblcBuAjxdGESIvnnyfPkQdQN_OWF1A0E-mhgBMio-bSL09Ylcoq6rzWYaAm-2EALw_wcB">The Royal British Legion</a>
        <v-icon size="x-small" icon="mdi-open-in-new"></v-icon>
        is a charity that Nicks' Grandad (Eric) supported for many years, they aim to support serving and ex-serving personnel of the British Armed Forces and their families.
      </div>
    </v-row>
    <v-row justify="center">
      <a target="_blank" href="https://grassroots.org.uk/product/give-now/" class="mb-8 mr-8">
        <v-img src="~@/assets/grass-roots.jpg" height="100px" width="100px"></v-img>
      </a>
      <a target="_blank" href="https://www.britishlegion.org.uk/get-involved/ways-to-give/donate?_gl=1*pba864*_up*MQ..&gclid=Cj0KCQjw3vO3BhCqARIsAEWblcBuAjxdGESIvnnyfPkQdQN_OWF1A0E-mhgBMio-bSL09Ylcoq6rzWYaAm-2EALw_wcB" class="mb-8 ml-8">
        <v-img class="mt-5" src="~@/assets/royal-british-legion.png" width="100px"></v-img>
      </a>
    </v-row>

    <v-row justify="center">
      <div class="text-body-1 text-center mb-4">
        If you want to contribute towards the cost of the honeymoon, you can do so by sending money to the following account:
      </div>
    </v-row>
    <v-row justify="center">
      <v-col class="col-5 text-right pr-1 pb-0 pt-0">
        <div class="font-weight-bold">Name:</div>
      </v-col>
      <v-col class="col-6 text-start pl-1 pb-0 pt-0">
        <div class="font-weight-regular">Nicholas Kinsey</div>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col class="col-5 text-right pr-1 pb-0 pt-0">
        <div class="font-weight-bold">Sort Code:</div>
      </v-col>
      <v-col class="col-6 text-start pl-1 pb-0 pt-0">
        <div class="font-weight-regular">04-00-75</div>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col class="col-5 text-right pr-1 pb-0 pt-0">
        <div class="font-weight-bold">Account Number:</div>
      </v-col>
      <v-col class="col-6 text-start pl-1 pb-0 pt-0">
        <div class="font-weight-regular">10262733</div>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col class="col-5 text-right pr-1 pb-0 pt-0">
        <div class="font-weight-bold">Reference:</div>
      </v-col>
      <v-col class="col-6 text-start pl-1 pb-0 pt-0">
        <div class="font-weight-regular">Wedding Gift</div>
      </v-col>
    </v-row>

    <v-row justify="center">
      <div class="text-body-1 text-center mt-8 mb-2">
        Please send any gifts from the registry list to the following address:
      </div>
    </v-row>
    <v-row justify="center">
      <div class="text-body-1 text-center">
        4 Arlowe Drive
        <br/>
        Shirley
        <br/>
        Southampton
        <br/>
        SO16 6DN
      </div>
    </v-row>
    <v-row justify="center">
      <v-btn
        class="mb-4 mt-8"
        color="rgb(181,147,223)"
        variant="elevated"
        append-icon="mdi-open-in-new"
        href="https://www.johnlewis.com/wish-list/NPMMWRD"
        target="_blank"
      >
        To Registry List
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "RegistryListTab",
  computed: {
    mobile() {
      return this.$vuetify.display.mobile
    },
  },
}

// <div class="text-body-1 text-center">
//         Having you attend our wedding is the greatest gift of all, however, if you're looking for wedding gift ideas, we've created a registry list of items that would be useful for us as we begin our new lives together.
//         <br/>
//         <br/>
//         Please send any gifts from the registry list to the following address:
//         <br/>
//         <br/>
//         Nicholas Kinsey
//         <br/>
//         4 Arlowe Drive
//         <br/>
//         Shirley
//         <br/>
//         Southampton
//         <br/>
//         SO16 6DN
//       </div>
</script>

<style>


</style>
