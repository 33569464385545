<template>
  <v-container class="pa-0 ma-0">
    <v-img src="~@/assets/invite.jpg" class="mb-2" height="50vh" cover></v-img>
    <a target="_blank" class="text-center" style="font-size:8pt;width:70px;display:inline-block;" href="https://www.emilyscotneyphotography.com/">
      Emily Scotney Photography
    </a>
    <v-icon size="xx-small" icon="mdi-open-in-new"></v-icon>

    <!-- Church -->
    <div class="text-center">
      <v-img src="~@/assets/invite-top.png" height="7vh" class="mt-2"></v-img>
      <v-img src="~@/assets/invite-names.png" height="40vh"></v-img>
      <br/>
      <v-img src="~@/assets/invite-date.png" height="10vh"></v-img>
      <div class="invite-body-small">(please be seated by 11:45)</div>
      <br/>
      <div class="invite-body">Highfield Church</div>
      <div class="invite-body">Highfield Lane, Southampton</div>
      <div class="invite-body">SO17 1RL</div>
      <v-btn
        class="mt-2 mb-4"
        color="rgb(181,147,223)"
        variant="outlined"
        size="small"
        append-icon="mdi-open-in-new"
        href="https://www.google.com/maps/dir/?api=1&destination=Highfield+Church%2C+Highfield+Lane%2C+Southampton%2C+UK"
        target="_blank"
      >
        Directions
      </v-btn>
      <br/>
      <div class="invite-body">The church has limited parking, however, ample parking is available on nearby roads.</div>
      <div class="invite-body">We advise parking on Brookvale Road, Westwood Road or Winn Road.</div>

      <br/>
    </div>

    <!-- Breakfast -->
    <div v-if="attendingBreakfast" class="text-center">
      <v-img src="~@/assets/invite-reception.png" height="7vh"></v-img>
      <div class="invite-body">To follow after the service</div>
    </div>

    <!-- Evening -->
    <div v-if="attendingEvening && !attendingBreakfast" class="text-center">
      <v-img src="~@/assets/invite-evening-reception.png" height="7vh"></v-img>
      <div class="invite-body-big">7pm</div>
    </div>

    <div v-if="attendingEvening" class="text-center">
      <br/>
      <div class="invite-body">Royal Victoria Chapel</div>
      <div class="invite-body">Royal Victoria Country Park</div>
      <div class="invite-body">Southampton</div>
      <div class="invite-body">SO31 5GA</div>
      <v-btn
        class="mt-2 mb-4"
        color="rgb(181,147,223)"
        variant="outlined"
        size="small"
        append-icon="mdi-open-in-new"
        href="https://www.google.com/maps/dir/?api=1&destination=Royal+Victoria+Country+Park"
        target="_blank"
      >
        Directions
      </v-btn>
      <br/>
      <div class="invite-body">Please park in the overflow car park unless you require disabled access.</div>
      <div class="invite-body">A code will be made available on the day to validate your parking.</div>
      <br/>
      <div class="invite-body">Carriages to follow at 11pm</div>
    </div>

    <!-- Bottom of page -->
    <div class="text-center">
      <v-img src="~@/assets/invite-bottom.png" class="mt-5" height="10vh"></v-img>
    </div>

  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "InviteTab",
  computed: {
    ...mapGetters('auth', {
      attendingEvening: 'attendingEvening',
      attendingBreakfast: 'attendingBreakfast'
    }),
    mobile() {
      return this.$vuetify.display.mobile
    },
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&family=Roboto&display=swap');

.invite-body-big {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  color: rgb(155,144,138);
}

.invite-body {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  color: rgb(155,144,138);
}

.invite-body-small {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  color: rgb(155,144,138);
}

</style>
