<template>
  <v-container>
    <v-img src="~@/assets/faq.jpg" class="mb-2" height="50vh" cover></v-img>

    <div class="text-h4 mb-5 text-center">FAQ</div>
    <v-row justify="center" class="mb-3">
      <div class="mt-2 text-body-1 text-center">
        If you can't find an answer to your question here, you can contact us at: <a href="mailto:kinseywedding2025@gmail.com">kinseywedding2025@gmail.com</a>
      </div>
    </v-row>
    <v-row class="ml-8 mr-8 mt-15 pt-5" v-for="(item, index) in questions.filter(i => checkIfItemVisible(i))" :key="index">
      <v-col cols="12">
        <v-row>
          <div class="text-body-1 font-weight-bold">
            {{ item.question }}<br/>
          </div>
        </v-row>
        <v-row>
          <div class="text-body-1 smallLine">
            <br/>{{ item.answer }}
            <div v-if="item.question == 'Do I need to book a taxi?'" style="display: inline;">
              <a target="_blank" href="https://radiotaxisonline.co.uk/">RadioTaxis</a>
              <v-icon size="x-small" icon="mdi-open-in-new"></v-icon>
            </div>
            <div v-if="item.question == 'How do I share my photos?'" style="display: inline;">
              <a target="_blank" href="https://drive.google.com/drive/folders/14YKHYrpMg1RjN6Mvs8Dkhrj4f6okqj0A?usp=drive_link">here</a>
              <v-icon size="x-small" icon="mdi-open-in-new"></v-icon>
            </div>
          </div>
        </v-row>
      </v-col>
    </v-row>
    <br/>
    <br/>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "FAQTab",
  computed: {
    ...mapGetters('auth', {
      attendingEvening: 'attendingEvening',
      attendingBreakfast: 'attendingBreakfast'
    })
  },
  data() {
    return {
      questions: [
        {
          question: "When is the RSVP deadline?",
          answer: "31st January 2025",
          onlyBreakfastOrEvening: true,
        },

        {
          question: "Are children welcome?",
          answer: "Yes children are more than welcome at our wedding, but please check your invite for who has been invited within your group."
        },

        {
          question: "Can I bring a plus one?",
          answer: "Everyone in your party should be listed on your invite, if you think we have missed someone from your party then please get in touch!"
        },

        {
          question: "What if I have a dietary restriction?",
          answer: "Please let us know any dietary requirements in the 'Additional Info' section of the RSVP.",
          onlyBreakfastOrEvening: true,
        },

        {
          question: "What if I have additional needs?",
          answer: "We want everyone to feel as included in the day as possible! So please put any additional needs you may have in the 'Additional Info' section of the RSVP.",
          onlyBreakfastOrEvening: true,
        },

        {
          question: "Are the venues wheelchair accessible?",
          answer: "Yes, all venues are wheelchair accessible."
        },

        {
          question: "Will there be food after service?",
          answer: "Sandwiches, cake and light refreshments will be available after the service."
        },

        {
          question: "I need to stay overnight, where should I stay?",
          answer: "There are lots of accomodation options in Southampton, we have collated a small list on the 'Accomodation' page.",
          onlyBreakfastOrEvening: true,
        },

        {
          question: "Do I need to book a taxi?",
          answer: "Royal Victoria Country Park does not have many transport links, so you may well need to book a taxi. Uber is available but covers that area less often, instead, we would recommend booking through a local firm called: ",
          onlyBreakfastOrEvening: true,
        },

        {
          question: "When do we need leave the venue?",
          answer: "Carriages are at 11pm, however the venue will remain open till midnight.",
          onlyBreakfastOrEvening: true,
        },

        {
          question: "What should I do between the ceremony and evening reception?",
          answer: "We're sorry we haven't been able to invite you to the sit-down meal. You are more than welcome to have a picnic / stroll / explore around Royal Victoria Country Park whilst waiting for the evening entertainment to start.",
          onlyEvening: true,
        },

        {
          question: "Where should I park?",
          answer: "Please check the invite page for parking information."
        },

        {
          question: "Will I have to pay for parking?",
          answer: "Usually you have to pay for parking at Royal Victoria Country Park, however we will have a code available for you to enter into the machines to validate your parking.",
          onlyBreakfastOrEvening: true,
        },

        {
          question: "What are the wedding colours?",
          answer: "Blue & Purple!"
        },

        {
          question: "What should I wear?",
          answer: "Whatever you feel comfortable in, but come prepared for dancing in the evening!"
        },

        {
          question: "Can I take photos?",
          answer: "Yes! Please do take photos throughout the day, but not during the ceremony."
        },

        {
          question: "How do I share my photos?",
          answer: "You can share your photos from the day "
        },

        {
          question: "Is there a gift registry?",
          answer: "Yes! See the 'Registry List' page on the website."
        }
      ]
    }
  },
  methods: {
    checkIfItemVisible(item) {
      if ((this.attendingBreakfast || !this.attendingEvening) && item.onlyEvening) {
        return false
      }

      if (!this.attendingBreakfast && !this.attendingEvening && item.onlyBreakfastOrEvening) {
        return false
      }

      return true
    }
  },
}
</script>

<style>
.smallLine {
  line-height: 1.0;
}

</style>
