export const NONE = 'NONE';
export const ADMIN = 'ADMIN';
export const ALL_DAY = 'ALL_DAY';
export const CHURCH_AND_EVENING = 'CHURCH_AND_EVENING';
export const CHURCH_ONLY = 'CHURCH_ONLY';

export const ADMIN_PASSWORD = 580492534;
export const ALL_DAY_PASSWORD = -491222947;
export const CHURCH_AND_EVENING_PASSWORD = 106683528;
export const CHURCH_ONLY_PASSWORD = -686753640;

// Email JS
export const EMAIL_JS_PUBLIC_KEY = 'KNpnN6FieEA6ObwCz';
export const EMAIL_JS_SERVICE_ID = 'service_xgt6z5t';
export const EMAIL_JS_RSVP_TEMPLATE_ID = 'template_uku6nam';
export const EMAIL_JS_FORGOTTEN_PASSCODE_TEMPLATE_ID = 'template_xzsr3t9';
