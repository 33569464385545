<template>
  <v-container>
    <v-img src="~@/assets/accomodation.jpg" class="mb-2" height="50vh" cover></v-img>

    <div class="text-h4 mb-5 text-center">Accomodation</div>
    <v-row justify="center">
      <div class="text-body-1 text-center">
        There are many hotels within Southampton to choose from, we have compiled a small list here to help you choose the best option for you.
        <br/>
        <br/>
        Please note that there is a Toll on The Itchen Bridge (between Southampton City and Royal Victoria Country Park), with a cost of £1 for cars.
        This bridge can easily be avoided and there are alternative routes.
        Click <a target="_blank" href="https://www.southampton.gov.uk/travel-transport/apply-pay/itchen-bridge/">here</a><v-icon size="x-small" icon="mdi-open-in-new"></v-icon> for further details.
      </div>
    </v-row>
    <v-row justify="center">
      <v-col cols="10">
        <v-select
          v-model="sortBy"
          class="mt-4"
          :items="sortableItems"
          density="compact"
          label="Sort By"
        ></v-select>
      </v-col>
    </v-row>
    <v-row v-for="(accomodation, index) in sortedAccomodationItems" :key="index" justify="center" class="mt-6">
      <v-card
        width="400px"
        :color="index % 2 == 0 ? 'rgb(181,147,223)' : 'rgb(100, 149, 237)'"
        variant="outlined"
      >
        <v-card-title class="text-wrap">
          <a target="_blank" :href="accomodation.link" style="color: inherit">
            {{ accomodation.name }}
          </a>
          <v-icon size="x-small" icon="mdi-open-in-new"></v-icon>
        </v-card-title>

        <v-card-text>
          <v-row class="mt-2">
            <v-col class="offset-1 col-5 text-start pa-1">
              <div class="font-weight-bold">Dist. to Church:</div>
            </v-col>
            <v-col class="col-6 text-start pa-1">
              <div class="font-weight-regular">{{ accomodation.distanceToChurch }} miles</div>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="offset-1 col-5 text-start pa-1">
              <div class="font-weight-bold">Dist. to Venue:</div>
            </v-col>
            <v-col class="col-6 text-start pa-1">
              <div class="font-weight-regular">{{ accomodation.distanceToRVCP }} miles</div>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="offset-1 col-5 text-start pa-1">
              <div class="font-weight-bold">Price:</div>
            </v-col>
            <v-col class="col-6 text-start pa-1">
              <div class="font-weight-regular">{{ accomodation.price }}</div>
            </v-col>
          </v-row>
          <v-row v-if="accomodation.nodes">
            <v-col class="offset-1 col-5 text-start pa-1">
              <div class="font-weight-bold">Notes:</div>
            </v-col>
            <v-col class="col-6 text-start pa-1">
              <div class="font-weight-regular">{{ accomodation.notes }}</div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "AccomodationTab",
  data() {
    return {
      sortableItems: ['Distance to Venue', 'Distance to Church', 'Price'],
      sortBy: 'Distance to Venue',
      sortedAccomodationItems: [
        {
          name: 'Bell Tent Glamping',
          distanceToChurch: 8.0,
          distanceToRVCP: 0.1,
          price: '££',
          link: 'https://www.belltentglamping.net/en'
        },
        {
          name: 'The Mansion',
          distanceToChurch: 8.6,
          distanceToRVCP: 6.6,
          price: '££££',
          link: 'http://www.coldeastmansion.co.uk/'
        },
        {
          name: 'Premier Inn, Southampton City',
          distanceToChurch: 2.1,
          distanceToRVCP: 5.7,
          price: '£££',
          link: 'https://www.premierinn.com/gb/en/hotels/england/hampshire/southampton/southampton-city-centre.html?cid=GLBC_SOUMTI',
          notes: '4.4mi to RVCP via Toll'
        },
        {
          name: 'Premier Inn, Cumberland Place',
          distanceToChurch: 1.8,
          distanceToRVCP: 6.4,
          price: '£££',
          link: 'https://www.premierinn.com/gb/en/hotels/england/hampshire/southampton/southampton-cumberland-place.html?cid=GLBC_SOUCUM',
          notes: '4.4mi to RVCP via Toll'
        },
        {
          name: 'Leonardo Hotel',
          distanceToChurch: 6.2,
          distanceToRVCP: 6.2,
          price: '£££',
          link: 'https://www.leonardohotels.co.uk/hotels/southampton',
          notes: 'No parking; 4.7mi to RVCP via Toll'
        },
        {
          name: 'Travelodge, Southampton',
          distanceToChurch: 1.0,
          distanceToRVCP: 6.7,
          price: '£',
          link: 'https://www.travelodge.co.uk/hotels/66/Southampton-hotel',
          notes: '5.8mi to RVCP via Toll'
        },
        {
          name: 'Chilworth Manor Hotel',
          distanceToChurch: 6.2,
          distanceToRVCP: 11.5,
          price: '££££',
          link: 'https://www.chilworthmanorhotel.com/',
          notes: ''
        }
      ].sort((a, b) => a.distanceToRVCP - b.distanceToRVCP),
    }
  },
  watch: {
    sortBy () {
      if (this.sortBy === 'Distance to Church') {
        this.sortedAccomodationItems = this.sortedAccomodationItems.sort((a, b) => a.distanceToChurch - b.distanceToChurch)
      }

      if (this.sortBy === 'Distance to Venue') {
        this.sortedAccomodationItems = this.sortedAccomodationItems.sort((a, b) => a.distanceToRVCP - b.distanceToRVCP)
      }

      if (this.sortBy === 'Price') {
        this.sortedAccomodationItems = this.sortedAccomodationItems.sort((a, b) => a.price.length - b.price.length)
      }
    },
  }
}

</script>

<style>

</style>
