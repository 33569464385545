<template>
  <div id="app">
    <div id="background-image">
      <HomeView v-if="isAuthenticated"/>
      <LoginView v-else/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import HomeView from './views/HomeView.vue';
import LoginView from './views/LoginView.vue';

export default {
  name: 'App',
  computed: {
    ...mapGetters('auth', {
      isAuthenticated: 'isAuthenticated'
    }),
  },
  created () {
    document.title = "Wedding of Nick & Hannah";
  },
  components: {
    HomeView,
    LoginView
  },
}
</script>

<style>
#background-image {
  /* The background image */
  background-image: url("~@/assets/login-background.jpg");

  /* Set a specified height, or the minimum height for the background image */
  min-height: 100vh;

  /* Set background image to fixed (don't scroll along with the page) */
  background-attachment: fixed;

  /* Center the background image */
  background-position: center;

  /* Set the background image to no repeat */
  background-repeat: no-repeat;

  /* Scale the background image to be as large as possible */
  background-size: cover;
}

iframe#webpack-dev-server-client-overlay{display:none!important}
</style>
